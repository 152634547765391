import axios from "axios";
// import { store } from "../store/store";
import { setKeys } from "../store/slices/SessionSlice";

const no_authentication = [
  "/security/login",
  "/sales/my-active-categories",
  "/sales/my-products",
  "/marketplace/user",
];  

const axiosApiInstance = axios.create();

let store: any;
export const injectStore = (_store: any) => {
  store = _store;
};

// Request interceptor for API calls
axiosApiInstance.interceptors.request.use(
  async (config) => {
    //@ts-ignore
    config.headers = {
      ...config.headers,
      Accept: "*/*",
      "Content-Type": "application/json",
      "X-App-Origin": "Tecopos-Marketplace",
      "X-App-Authorization": `Bearer ${process.env.REACT_APP_VERSION_MARKETPLACEACCESSKEY}`,
    };

    // const session = localStorage.getItem("session");
    const session = store.getState().session.key;
    const businessId = store.getState().session.businessId;

    const rute =
      config.url?.split(
        `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_VERSION_API}`
      )[1] ?? "";

    // eslint-disable-next-line no-mixed-operators
    if (
      (session !== null && !no_authentication.includes(rute)) ||
      rute === "/marketplace/user"
    ) {
      const keys = session!;
      //@ts-ignore
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${keys.token}`,
      };
    }

    if (rute.includes("/marketplace/order") || rute === "/marketplace/paymentgateway/create-tropipay-link") {
      config.headers = {
        ...config.headers,
        "X-App-BusinessId": JSON.stringify(businessId),
      };
    }

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// Response interceptor for API calls
axiosApiInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;

    if (error?.response?.status! === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      //   const session = localStorage.getItem("session");
      const session = store.getState().session.key;

      if (session) {
        const keys = session;
        try {
          await axios
            .post(
              `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_VERSION_API}/security/refresh-token`,
              {
                refresh_token: keys.refresh_token,
              }
            )
            .then(async (response) => {
              const new_session = {
                token: response.data.token,
                refresh_token: response.data.refresh_token,
              };
              //   localStorage.setItem("session", JSON.stringify(new_session));
              store.dispatch(setKeys(new_session));

              axiosApiInstance.defaults.headers.common["Authorization"] =
                "Bearer " + response.data.token;
              return axiosApiInstance(originalRequest);
            })
            .catch(async (error) => {
              localStorage.removeItem("session");
              return Promise.reject(error);
            });
        } catch (e) {
          localStorage.removeItem("session");
          return Promise.reject(error);
        }
      }
    }
    return Promise.reject(error);
  }
);

const get = async (path: string) => {
  const request = {
    url: `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_VERSION_API}${path}`,
    method: "GET",
  };

  return axiosApiInstance.get(request.url);
};

const post = async (path: string, body: object, config = {}) => {
  const request = {
    url: `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_VERSION_API}${path}`,
    method: "POST",
  };

  return axiosApiInstance.post(request.url, body, config);
};

const put = async (path: string, body: object) => {
  const request = {
    url: `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_VERSION_API}${path}`,
    method: "PUT",
  };

  return axiosApiInstance.put(request.url, body);
};

const patch = async (path: string, body: object) => {
  const request = {
    url: `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_VERSION_API}${path}`,
    method: "PATCH",
  };

  return axiosApiInstance.patch(request.url, body);
};

const deleteAPI = async (path: string, body: object) => {
  const request = {
    url: `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_VERSION_API}${path}`,
    method: "DELETE",
  };

  return axiosApiInstance.delete(request.url, { data: body });
};

export default {
  get,
  post,
  put,
  patch,
  deleteAPI,
};
