import { createSlice } from "@reduxjs/toolkit";
// import { closeSystem } from "../actions/globals";
import { User } from "../../interfaces/ServerInterfaces";

interface InitialState {
  key: { token: string; refresh_token: string } | null;
  businessId: number | null;
  staticBar: boolean;
  user: User | null;
}

const initialState: InitialState = {
  key: null,
  businessId: null,
  staticBar: true,
  user: null,
};

const sessionSlice = createSlice({
  initialState,
  name: "session",
  reducers: {
    setKeys: (state, action) => ({ ...state, key: action.payload }),
    setUser: (state, action) => ({ ...state, user: action.payload }),
    setBusinessId: (state, action) => ({
      ...state,
      businessId: action.payload,
    }),
    changeStaticBar: (state) => ({ ...state, staticBar: !state.staticBar }),
  },
//   extraReducers: (builder) =>
//     builder.addCase(closeSystem, (state) => ({ ...state, key: null })),
});

export const { setKeys, setUser, setBusinessId, changeStaticBar } =
  sessionSlice.actions;
export default sessionSlice.reducer;
