import { useState } from "react";
import query from "./APIServer";
import { OrderInterface } from "../interfaces/ServerInterfaces";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { setOrders } from "../store/slices/OrdersSlice";
import { toast } from "react-toastify";
import { setTropiPayURL } from "../store/slices/FormSlice";

export const useServerOrders = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [allOrders, setAllOrders] = useState<OrderInterface[]>([]);
  const [orderDetails, setOrderDetails] = useState<OrderInterface>();

  const dispatch = useAppDispatch();

  const { orders } = useAppSelector((state) => state.orders);

  const manageErrors = (error: any) => {
    console.log({ error });
    if (error.status === 401) {
      toast.error(error.response?.data?.message);
      // navigate("/");
    }
    if (error.response?.data?.message) {
      if (
        error.response?.data?.message ===
        "SequelizeValidationError: notNull Violation: Price.amount cannot be null"
      ) {
        toast.error("Introduzca la dirección de envío");
      } else if (
        error.response?.data?.message ===
        "TypeError: Cannot read properties of undefined (reading 'error')"
      ) {
        toast.error('Hubo un error para generar el enlace de pago. Vuelva a intentar y si el problema persiste contacte con el centro de atención de Tecopos', {
          position: "top-center",
          autoClose: 6000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });
        
      } else {
        toast.error(error.response?.data?.message);
      }

      return;
    } else {
      toast.error(
        "Upss, ha ocurrido un error inesperado. \n Intente de nuevo o consulte con su administrador..."
      );
      return;
    }
  };

  const getMyOrders = async () => {
    setIsLoading(true);
    await query
      .get("/marketplace/order/")
      .then((resp) => {
        dispatch(setOrders(resp.data.items));
        setAllOrders(resp.data.items);
      })
      .catch((e) =>
        setError(e.message.data ?? "Ha ocurrido un error vuelva a intentarlo")
      );
    setIsLoading(false);
  };

  const getOrderDetails = async (idOrder: number) => {
    setIsLoading(true);
    await query
      .get(`/marketplace/order/${idOrder}`)
      .then((resp) => {
        setOrderDetails(resp.data);
        return resp.data;
      })
      .catch((e) =>
        setError(e.message.data ?? "Ha ocurrido un error vuelva a intentarlo")
      );
    setIsLoading(false);
  };

  const createNewOrder = async (data: any) => {
    setIsLoading(true);
    await query
      .post("/marketplace/order", data)
      .then((resp: any) => {
        if (orders !== undefined && orders !== null) {
          const newOrderArray = [resp.data, ...orders];
          dispatch(setOrders(newOrderArray));
        }

        const dataId: number = resp.data.id;
        if (data.paymentGatewayId === 5) {
          CreateTropipayLink(dataId);
        } else {
          toast.success(`Órden ${resp.data.id} creada satisfactoriamente`);
          dispatch(setTropiPayURL("clearCarShop"));
        }
      })
      .catch((e) => manageErrors(e));
    setIsLoading(false);
  };

  const CreateTropipayLink = async (orderID: number) => {
    setIsLoading(true);
    dispatch(setTropiPayURL("tropiPayLinkLoading"));

    const data = {
      orderId: orderID,
    };

    await query
      .post("/marketplace/paymentgateway/create-tropipay-link", data)
      .then((data) => {
        dispatch(setTropiPayURL(data.data.shortUrl));
      })
      .catch((e) => {
        manageErrors(e);
        dispatch(setTropiPayURL(null));
      });

    setIsLoading(false);
  };

  const CancelOrder = async (orderID: number) => {
    setIsLoading(true);

    const raw = {
      id: orderID,
    };

    await query
      .deleteAPI(`/marketplace/order/${orderID}`, raw)
      .then((data) => {
        dispatch(
          setOrders(
            orders?.map((order) =>
              order.id !== data.data.id ? order : data.data
            )
          )
        );
      })
      .catch((e) => manageErrors(e));

    setIsLoading(false);
  };

  return {
    getMyOrders,
    getOrderDetails,
    createNewOrder,
    CreateTropipayLink,
    CancelOrder,

    isLoading,
    allOrders,
    orderDetails,
    error,
  };
};
