import { createSlice } from "@reduxjs/toolkit";
import { OrderInterface } from "../../interfaces/ServerInterfaces";

interface InitialInterface {
  orders: OrderInterface[] | undefined;
  detailedOrders: OrderInterface[];
}

const initialState: InitialInterface = {
  orders: [],
  detailedOrders: []
};

const ordersSlice = createSlice({
  initialState,
  name: "orders",
  reducers: {
    addDetailedOrder: (state, action) => ({
      ...state,
      detailedOrders: [ ...state.detailedOrders, { ...action.payload }],
    }),
    setOrders: (state, action) => ({
      ...state,
      orders: action.payload
    })
  },
});

export const { addDetailedOrder, setOrders } = ordersSlice.actions;

export default ordersSlice.reducer;
