import {
  faChevronRight,
  faHeart,
  faStar,
  faTags,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { ProductInterface } from "../interfaces/ServerInterfaces";
import imageDefault from "../assets/jpg/no-imagen-shop.jpg"
import { getColorShowRemainQuantities, getShowRemainQuantities } from "../utils/functions";

interface Props {
  product: ProductInterface;
  onClick: Function;
  priceSystemId: number;
  cardWidth: string;
  addCart: Function;
  includeShop: boolean
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export const CardProduct = ({
  product,
  onClick,
  priceSystemId,
  cardWidth,
  addCart,
  includeShop
}: Props) => {


  return (
    <>
      <div
        className={`h-96 ${cardWidth}   rounded-lg scrollbar-hide  overflow-hidden   shadow-md inline-block  cursor-pointer  ease-in-out duration-300`}
      >
        <div className="h-52  bg-slate-200 group-hover:opacity-75 ">
          {/* Old Version of Code to show an image */}

          {/* <img
            src={
              product.images.length > 0
                ? product.images[0].src
                : imageDefault
            }
            alt={product.name}
            onClick={() => onClick(product)}
            className="h-full w-full object-cover object-center sm:h-full sm:w-full"
          /> */}
          {/* Determines when to display an image carousel, a single image, and the default image */}

          {
            (<img
              src={
                product?.images
                  .length !==
                  undefined &&
                  product?.images.length > 0
                  ? product?.images[0]
                    .src
                  : imageDefault
              }
              alt={product?.name}
              className="h-full max-h-52 w-full object-cover object-center"
              onClick={() => onClick(product)}
            />)
          }
        </div>

        <div className="flex flex-1 w-full whitespace-nowrap  flex-col space-y-1 p-2">
          <h3
            className="text-lg truncate font-medium text-slate-900"
            onClick={() => onClick(product)}
          >
            {product.name}
          </h3>
          <p className="text-sm truncate h-5 -mt-2 text-slate-500">
            {" "}
            {product.description}
          </p>
          {product.showRemainQuantities &&
            <p className=
              {classNames(
                `${getColorShowRemainQuantities(product.totalQuantity)}`
                ,
                "text-xs font-medium truncate h-5 -mt-4"
              )}
            >
              {" "}

              {getShowRemainQuantities(product.totalQuantity)

              }
            </p>}
          <div className="flex flex-row flex-nowrap ">
            {product.onSale && (
              <h5 className={`text-base font-medium mr-2 text-slate-900 `}>
                {product.onSalePrice?.amount +
                  " " +
                  product.onSalePrice?.codeCurrency}
              </h5>
            )}
            <h5
              className={`text-base  font-medium  text-slate-900 ${product.onSale && "line-through text-slate-500"
                }`}
            >
              {product.prices.length === 1
                // ?  product.prices[0].price + " " + product.prices[0].codeCurrency
                ? Intl.NumberFormat("en-IN").format(product.prices[0].price) + (Number.isInteger(product.prices[0].price) ? ".00 " : " ") + product.prices[0].codeCurrency
                : product.prices.map(
                  (item) =>
                    item.priceSystemId === priceSystemId &&
                    item.price + " " + item.codeCurrency
                )}
            </h5>
          </div>
          {
            !includeShop ?
              <button
                type="button"
                onClick={() => {
                  onClick(product)
                }}
                className="w-full overflow-hidden truncate   inline-flex  items-center   justify-center text-center rounded-md border border-transparent bg-orange-100  py-2 text-sm font-medium text-orange-700 hover:bg-orange-200 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
              >
                {product.onSale && (
                  <FontAwesomeIcon
                    icon={faTags}
                    className=" mx-1"
                    size="sm"
                    aria-hidden="true"
                  />
                )}
                {product?.suggested && (
                  <FontAwesomeIcon
                    icon={faHeart}
                    className=" mx-1"
                    size="sm"
                    aria-hidden="true"
                  />
                )}
                Ver más
              </button>
              :
              <>
                <button
                  type="button"
                  className={
                    (product.stockLimit && product?.totalQuantity === 0) || product?.totalQuantity === 0
                      ? "w-full overflow-hidden truncate inline-flex  items-center   justify-center text-center rounded-md border border-transparent bg-red-600  py-2 text-sm font-medium text-white  focus:outline-none focus:ring-2 focus:ring-red-900 focus:ring-offset-2"
                      : product.stockLimit && product?.totalQuantity > 0
                        ? "w-full overflow-hidden truncate   inline-flex  items-center   justify-center text-center rounded-md border border-transparent bg-orange-100  py-2 text-sm font-medium text-orange-700 hover:bg-orange-200 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
                        : product.stockLimit
                          ? "w-full overflow-hidden truncate inline-flex  items-center   justify-center text-center rounded-md border border-transparent bg-red-600  py-2 text-sm font-medium text-white  focus:outline-none focus:ring-2 focus:ring-red-900 focus:ring-offset-2"
                          : "w-full overflow-hidden truncate   inline-flex  items-center   justify-center text-center rounded-md border border-transparent bg-orange-100  py-2 text-sm font-medium text-orange-700 hover:bg-orange-200 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
                  }
                  onClick={() => {
                    (product.totalQuantity > 0 || (product.stockLimit && product.totalQuantity > 0)) && (product.type === "VARIATION"
                      ? onClick(product)
                      : addCart(product))
                  }}
                >
                  {product.onSale && (
                    <FontAwesomeIcon
                      icon={faTags}
                      className=" mx-1"
                      size="sm"
                      aria-hidden="true"
                    />
                  )}
                  {product?.suggested && (
                    <FontAwesomeIcon
                      icon={faHeart}
                      className=" mx-1"
                      size="sm"
                      aria-hidden="true"
                    />
                  )}
                  {(product.stockLimit && product?.totalQuantity === 0) || product?.totalQuantity === 0
                    ? "Agotado"
                    : product.stockLimit && product?.totalQuantity > 0
                      ? product.type === "VARIATION"
                        ? "Mostrar opciones"
                        : "Añadir a la bolsa"
                      : product.stockLimit
                        ? "Agotado"
                        : product.type === "VARIATION"
                          ? "Mostrar opciones"
                          : "Añadir a la bolsa"
                  }
                </button>

                {/* Latest code of exhausted/adding_to_cart button */}

                {/* {!product.stockLimit ?
                  <button
                    type="button"
                    className="w-full overflow-hidden truncate   inline-flex  items-center   justify-center text-center rounded-md border border-transparent bg-red-600  py-2 text-sm font-medium text-white  focus:outline-none focus:ring-2 focus:ring-red-900 focus:ring-offset-2"
                  >
                    {product.onSale && (
                      <FontAwesomeIcon
                        icon={faTags}
                        className=" mx-1"
                        size="sm"
                        aria-hidden="true"
                      />
                    )}
                    {product?.suggested && (
                      <FontAwesomeIcon
                        icon={faHeart}
                        className=" mx-1"
                        size="sm"
                        aria-hidden="true"
                      />
                    )}
                    ¡Agotado!
                  </button>
                  :

                  <button
                    type="button"
                    onClick={() => {
                      product.type === "VARIATION"
                        ? onClick(product)
                        : addCart(product);
                    }}
                    className="w-full overflow-hidden truncate   inline-flex  items-center   justify-center text-center rounded-md border border-transparent bg-orange-100  py-2 text-sm font-medium text-orange-700 hover:bg-orange-200 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
                  >
                    {product.onSale && (
                      <FontAwesomeIcon
                        icon={faTags}
                        className=" mx-1"
                        size="sm"
                        aria-hidden="true"
                      />
                    )}
                    {product?.suggested && (
                      <FontAwesomeIcon
                        icon={faHeart}
                        className=" mx-1"
                        size="sm"
                        aria-hidden="true"
                      />
                    )}
                    {product.type === "VARIATION"
                      ? "Mostrar opciones"
                      : "Añadir a la bolsa"}
                  </button>

                } */}
              </>
          }
        </div>
      </div>
    </>
  );
};
